// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-ourstory-js": () => import("../src/pages/ourstory.js" /* webpackChunkName: "component---src-pages-ourstory-js" */),
  "component---src-pages-registry-js": () => import("../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rsvp-js": () => import("../src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-safety-js": () => import("../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-travel-js": () => import("../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-wedding-js": () => import("../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */)
}

